import React from 'react';

const PrivacyPolicy = () => {
	return (
		<div className='privacy'>
			<h1>Privacy and Policy</h1>
			<p>
				<b>Text message communications.</b> We use text messaging to communicate
				with you about your service. Normal data and messaging rates apply, and
				the messaging frequency may vary. Mobile Carriers are not liable for
				delayed or undelivered messages.
				<br />
				<br />
				No mobile information will be shared with third parties/affiliates for
				marketing/promotional purposes. All other categories exclude text
				messaging originator opt-in data and consent; this information will not
				be shared with or obtained by any third parties.
				<br />
				<br />
				<b>Opt-out of text message communications.</b> You may opt out of text
				messaging at any time by replying to any message with STOP or contacting
				us at <a href='mailto:ecod24@gmail.com'>Ecod24@gmail.com</a>. This will
				end the communications from that particular phone number. You may
				continue to receive service-related and other non-marketing text
				messages from other phone numbers managed by Company, and you may opt
				out of those in a similar fashion.
			</p>
		</div>
	);
};

export default PrivacyPolicy;
